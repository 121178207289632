import {Link} from 'react-router-dom'
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Home() {
    return(
        <>
            <Header/>
            <section className="slider">
                <div className="banner">
                    <div id="rev_slider_35_1" className="rev_slider fullwidthabanner">
                        <ul>
                            <li data-transition="scaledownfromtop" data-masterspeed="700"><img src="images/roofing-job.jpg"
                                                                                               alt="Image"/>
                                <div className="tp-caption lfb ltt tp-resizeme start"
                                     data-x="left"
                                     data-hoffset="0"
                                     data-y="center"
                                     data-voffset="0"
                                     data-speed="600"
                                     data-start="400"
                                     data-easing="Power4.easeOut"
                                     data-splitin="none"
                                     data-splitout="none"
                                     data-elementdelay="0.1"
                                     data-endelementdelay="0.1"
                                     data-endspeed="500"
                                     data-endeasing="Power4.easeIn">
                                    <div className="text-container">
                                        <h6>Servicing Jacksonville for More than 20 Years</h6>
                                        <h2>Experience you can count on<br/>
                                            and trust.</h2>
                                    </div>
                                </div>
                            </li>
                            {/*// <!-- end banner 1 -->*/}
                            <li data-transition="scaledownfromtop" data-masterspeed="700"><img src="images/roofing-job-2.jpg"
                                                                                               alt="Image"/>
                                <div className="tp-caption lfb ltt tp-resizeme start"
                                     data-x="left"
                                     data-hoffset="0"
                                     data-y="center"
                                     data-voffset="0"
                                     data-speed="600"
                                     data-start="400"
                                     data-easing="Power4.easeOut"
                                     data-splitin="none"
                                     data-splitout="none"
                                     data-elementdelay="0.1"
                                     data-endelementdelay="0.1"
                                     data-endspeed="500"
                                     data-endeasing="Power4.easeIn">
                                    <div className="text-container">
                                        <h6>Servicing Jacksonville for More than 20 Years</h6>
                                        <h2>Experience you can count on<br/>
                                            and trust.</h2>
                                    </div>
                                </div>
                            </li>
                            {/*// <!-- end banner 2 -->*/}
                            <li data-transition="scaledownfromtop" data-masterspeed="700"><img src="images/roofing-job-3.jpg"
                                                                                               alt="Image"/>
                                <div className="tp-caption lfb ltt tp-resizeme start"
                                     data-x="left"
                                     data-hoffset="0"
                                     data-y="center"
                                     data-voffset="0"
                                     data-speed="600"
                                     data-start="400"
                                     data-easing="Power4.easeOut"
                                     data-splitin="none"
                                     data-splitout="none"
                                     data-elementdelay="0.1"
                                     data-endelementdelay="0.1"
                                     data-endspeed="500"
                                     data-endeasing="Power4.easeIn">
                                    <div className="text-container">
                                        <h6>Servicing Jacksonville for More than 20 Years</h6>
                                        <h2>Experience you can count on<br/>
                                            and trust.</h2>
                                    </div>
                                </div>
                            </li>
                            {/*// <!-- end banner 3 -->*/}
                            {/*<li data-transition="scaledownfromtop" data-masterspeed="700"><img src="images/banner4.jpg"*/}
                            {/*                                                                   alt="Image"/>*/}
                            {/*    <div className="tp-caption lfb ltt tp-resizeme start"*/}
                            {/*         data-x="left"*/}
                            {/*         data-hoffset="0"*/}
                            {/*         data-y="center"*/}
                            {/*         data-voffset="0"*/}
                            {/*         data-speed="600"*/}
                            {/*         data-start="400"*/}
                            {/*         data-easing="Power4.easeOut"*/}
                            {/*         data-splitin="none"*/}
                            {/*         data-splitout="none"*/}
                            {/*         data-elementdelay="0.1"*/}
                            {/*         data-endelementdelay="0.1"*/}
                            {/*         data-endspeed="500"*/}
                            {/*         data-endeasing="Power4.easeIn">*/}
                            {/*        <div className="text-container">*/}
                            {/*            <h6>Building spectacular structures and buildings</h6>*/}
                            {/*            <h2>Don’t be an architecture of<br/>*/}
                            {/*                building, be the architecture</h2>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                            {/*// <!-- end banner 4 -->*/}
                            {/*<li data-transition="scaledownfromtop" data-masterspeed="700"><img src="images/banner5.jpg"*/}
                            {/*                                                                   alt="Image"/>*/}
                            {/*    <div className="tp-caption lfb ltt tp-resizeme start"*/}
                            {/*         data-x="left"*/}
                            {/*         data-hoffset="0"*/}
                            {/*         data-y="center"*/}
                            {/*         data-voffset="0"*/}
                            {/*         data-speed="600"*/}
                            {/*         data-start="400"*/}
                            {/*         data-easing="Power4.easeOut"*/}
                            {/*         data-splitin="none"*/}
                            {/*         data-splitout="none"*/}
                            {/*         data-elementdelay="0.1"*/}
                            {/*         data-endelementdelay="0.1"*/}
                            {/*         data-endspeed="500"*/}
                            {/*         data-endeasing="Power4.easeIn">*/}
                            {/*        <div className="text-container">*/}
                            {/*            <h6>Building spectacular structures and buildings</h6>*/}
                            {/*            <h2>Don’t be an architecture of<br/>*/}
                            {/*                building, be the architecture</h2>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                            {/*// <!-- end banner 5 -->*/}
                        </ul>
                        {/*// <!-- end ul -->*/}
                    </div>
                    {/*// <!-- end rev_slider -->*/}
                </div>
                {/*// <!-- end banner -->*/}
            </section>
            {/*// <!-- end slider -->*/}
            <section className="intro">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-xs-12 wow fadeInUp"><img src="images/roofer.jpg" alt="Image"
                                                                              className="left-image"/></div>
                        {/*// <!-- end col-6 -->*/}
                        <div className="col-md-6 col-xs-12 wow fadeInRight">
                            <div className="title-box">
                                <h5>Our experienced and organized team provides next-level roofing services</h5>
                                <h2>GET AN ESTIMATE</h2>
                                <span></span></div>
                            {/*// <!-- end title-box -->*/}
                            <p>Set up an estimate by calling 904-525-7517 or by filling out a quick form.
                                Someone from our team will be happy to assist you and get you on your way
                                to a better roof.</p>
                            {/*<div className="file-box"><img src="images/icon-file.png" alt="Image"/> <a href="#">DOWNLOAD*/}
                            {/*    PDF CATALOG</a> <a href="#">SEE COLOR SCHEME</a></div>*/}
                            {/*// <!-- end file-box -->*/}
                        </div>
                        {/*// <!-- end col-6 -->*/}
                    </div>
                    {/*// <!-- end row -->*/}
                </div>
                {/*// <!-- end container -->*/}
            </section>
            {/*// <!-- end intro -->*/}
            <section className="quote-bar wow fadeInUp">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            {/*<h4>We contribute to the society with the ones we protect</h4>*/}
                            <h3></h3>
                            <br/>
                            <a href="/estimate" className="site-btn">GET AN ESTIMATE <i className="ion-chevron-right"></i></a></div>
                        {/*// <!-- end col-12 -->*/}
                    </div>
                    {/*// <!-- end row -->*/}
                </div>
                {/*// <!-- end container -->*/}
            </section>
            {/*// <!-- end quote-bar -->*/}
            <section className="home-services wow fadeInUp">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 text-center">
                            <div className="title-box">
                                <h5>We provide an array of quality roof and roof related services.</h5>
                                <h2>WHAT WE DO</h2>
                                <span></span></div>
                            {/*// <!-- end title-box -->*/}
                        </div>
                        {/*// <!-- end col-12 -->*/}
                        <div className="col-xs-12">
                            <div className="carousel">
                                <div className="item">
                                    <figure><img src="images/roofing-job-2.jpg" alt="Image"/></figure>
                                    <span
                                        className="description"></span>
                                    <a href="#">RE-ROOFING</a> <span className="border"></span></div>
                                {/*// <!-- end item-->*/}
                                <div className="item">
                                    <figure><img src="images/roof-shingles.jpg" alt="Image"/></figure>
                                    <span
                                        className="description"></span>
                                    <a href="#">ROOF INSPECTION</a> <span className="border"></span></div>
                                {/*// <!-- end item -->*/}
                                <div className="item">
                                    <figure><img src="images/roofing-job.jpg" alt="Image"/></figure>
                                    <span
                                        className="description"></span>
                                    <a href="#">ROOF REPAIR</a> <span className="border"></span></div>
                                {/*// <!-- end item -->*/}
                                <div className="item">
                                    <figure><img src="images/skylight.jpg" alt="Image"/></figure>
                                    <span
                                        className="description"></span>
                                    <a href="#">SKYLIGHT INSTALLS</a> <span className="border"></span></div>
                                {/*// <!-- end item -->*/}
                                <div className="item">
                                    <figure><img src="images/gutter.jpg" alt="Image"/></figure>
                                    <span
                                        className="description"></span>
                                    <a href="#">GUTTER INSTALLS</a> <span className="border"></span></div>
                                {/*// <!-- end item -->*/}
                                <div className="item">
                                    <figure><img src="images/wdo.jpg" alt="Image"/></figure>
                                    <span
                                        className="description"></span>
                                    <a href="#">WDO SERVICES</a> <span className="border"></span></div>
                                {/*// <!-- end item -->*/}
                            </div>
                            {/*// <!-- end carousel -->*/}
                        </div>
                        {/*// <!-- end col-12 -->*/}
                    </div>
                    {/*// <!-- end row -->*/}
                </div>
                {/*// <!-- end container -->*/}
            </section>
            {/*// <!-- end home-services -->*/}
            <section className="quote background-1 wow fadeInUp" data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <a href="faqs" className="site-btn">Frequently Asked Questions <i className="ion-chevron-right"></i></a>
                            <br/>
                        </div>
                        {/*// <!-- end col-12 -->*/}
                    </div>
                    {/*// <!-- end row -->*/}
                </div>
                {/*// <!-- end container -->*/}
            </section>
            {/*// <!-- end video-bg -->*/}
            {/*<section className="home-features wow fadeInUp">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-md-2 col-xs-6 col-md-offset-1 col-xs-offset-0"><i className="icon"> <img*/}
            {/*                src="images/icon-1.jpg" alt="Image"/> </i>*/}
            {/*                <h4>Cemento</h4>*/}
            {/*                <p>Generate fast and high quality, add value to life.</p>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-2  col-xs-6"><i className="icon"> <img src="images/icon-2.jpg"*/}
            {/*                                                                          alt="Image"/> </i>*/}
            {/*                <h4>Painting</h4>*/}
            {/*                <p>Protect the natural environment, respect life.</p>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-2 col-xs-6"><i className="icon"> <img src="images/icon-3.jpg"*/}
            {/*                                                                         alt="Image"/> </i>*/}
            {/*                <h4>Cutting</h4>*/}
            {/*                <p>The dream of a life away from the chaos </p>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-2 col-xs-6"><i className="icon"> <img src="images/icon-4.jpg"*/}
            {/*                                                                         alt="Image"/> </i>*/}
            {/*                <h4>Digging</h4>*/}
            {/*                <p>But at the heart of the city becomes true</p>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-2 col-xs-6"><i className="icon"> <img src="images/icon-5.jpg"*/}
            {/*                                                                         alt="Image"/> </i>*/}
            {/*                <h4>Removing</h4>*/}
            {/*                <p>Garden pleasure in air with special design</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*// <!-- end home-features -->*/}
            {/*<section className="home-gallery text-center wow fadeInUp">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-xs-12">*/}
            {/*                <div className="title-box">*/}
            {/*                    <h5>Construction premium lifespace & workspaces</h5>*/}
            {/*                    <h2>OUR PROJECTS</h2>*/}
            {/*                    <span></span></div>*/}
            {/*                <ul className="filter">*/}
            {/*                    <li><a href="#" data-filter="*" className="current">ALL</a></li>*/}
            {/*                    <li><a href="#" data-filter=".recidences">RECIDENCES</a></li>*/}
            {/*                    <li><a href="#" data-filter=".offices">OFFICES</a></li>*/}
            {/*                    <li><a href="#" data-filter=".plazas">PLAZAS</a></li>*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <ul className="gallery thumbs">*/}
            {/*        <li className="recidences">*/}
            {/*            <figure><a href="images/project1.jpg" className="litebox"><img src="images/project1.jpg"*/}
            {/*                                                                           alt="Image"/></a></figure>*/}
            {/*        </li>*/}
            {/*        <li className="offices">*/}
            {/*            <figure><a href="images/project2.jpg" className="litebox"><img src="images/project2.jpg"*/}
            {/*                                                                           alt="Image"/></a></figure>*/}
            {/*        </li>*/}
            {/*        <li className="plazas">*/}
            {/*            <figure><a href="images/project3.jpg" className="litebox"><img src="images/project3.jpg"*/}
            {/*                                                                           alt="Image"/></a></figure>*/}
            {/*        </li>*/}
            {/*        <li className="recidences">*/}
            {/*            <figure><a href="images/project4.jpg" className="litebox"><img src="images/project4.jpg"*/}
            {/*                                                                           alt="Image"/></a></figure>*/}
            {/*        </li>*/}
            {/*        <li className="plazas">*/}
            {/*            <figure><a href="images/project5.jpg" className="litebox"><img src="images/project5.jpg"*/}
            {/*                                                                           alt="Image"/></a></figure>*/}
            {/*        </li>*/}
            {/*        <li className="offices">*/}
            {/*            <figure><a href="images/project6.jpg" className="litebox"><img src="images/project6.jpg"*/}
            {/*                                                                           alt="Image"/></a></figure>*/}
            {/*        </li>*/}
            {/*        <li className="offices plazas recidences">*/}
            {/*            <figure><a href="images/project7.jpg" className="litebox"><img src="images/project7.jpg"*/}
            {/*                                                                           alt="Image"/></a></figure>*/}
            {/*        </li>*/}
            {/*        <li className="recidences">*/}
            {/*            <figure><a href="images/project8.jpg" className="litebox"><img src="images/project8.jpg"*/}
            {/*                                                                           alt="Image"/></a></figure>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</section>*/}
            {/*// <!-- end home-gallery -->*/}
            {/*<section className="news wow fadeInUp">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-md-6">*/}
            {/*                <div className="news-box">*/}
            {/*                    <figure><img src="images/news1.jpg" alt="Image"/> <span className="icon"><i*/}
            {/*                        className="ion-flash"></i></span></figure>*/}
            {/*                    <h3>Construction "HOUSE" is almost finished… There is huge demand and rush on city</h3>*/}
            {/*                    <small>14TH OF DECEMBER</small> <span className="border"></span>*/}
            {/*                    <p>The first contracts have been signed in HOUSE, which will host many exclusive brands*/}
            {/*                        from Brazil and all over the World. Envato Corp, which has purchased 2 towers from*/}
            {/*                        the project...</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-6">*/}
            {/*                <div className="news-box">*/}
            {/*                    <figure><img src="images/news2.jpg" alt="Image"/> <span className="icon"><i*/}
            {/*                        className="ion-images"></i></span></figure>*/}
            {/*                    <h3>Construction company says “Real Estate will grow 10% during 2015”</h3>*/}
            {/*                    <small>27TH OF JANUARY</small> <span className="border"></span>*/}
            {/*                    <p>The first contracts have been signed in HOUSE, which will host many exclusive brands*/}
            {/*                        from Brazil and all over the World. Envato Corp, which has purchased 2 towers from*/}
            {/*                        the project...</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*// <!-- end news -->*/}
            {/*<section className="logos wow fadeInUp">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-xs-12 text-center">*/}
            {/*                <div className="title-box">*/}
            {/*                    <h5>Happy to introduce our amazing project partners</h5>*/}
            {/*                    <h2>PARTNERSHIP</h2>*/}
            {/*                    <span></span></div>*/}
            {/*                <ul>*/}
            {/*                    <li>*/}
            {/*                        <figure><img src="images/logo1.jpg" alt="Image"/></figure>*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        <figure><img src="images/logo2.jpg" alt="Image"/></figure>*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        <figure><img src="images/logo3.jpg" alt="Image"/></figure>*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        <figure><img src="images/logo4.jpg" alt="Image"/></figure>*/}
            {/*                    </li>*/}
            {/*                    <li>*/}
            {/*                        <figure><img src="images/logo5.jpg" alt="Image"/></figure>*/}
            {/*                    </li>*/}
            {/*                </ul>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
            {/*// <!-- end logos -->*/}
            <Footer/>
        </>

    )
}

