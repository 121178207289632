export default function Footer() {
    return(
        <>
            <section className="footer-bar wow fadeIn">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-xs-12"><i className="ion-clock icon"></i>
                            <h4>Monday to Saturday: 9:00 - 18:00 // Sunday: Closed</h4>
                        </div>
                        {/*// <!-- end col-8 -->*/}
                        <div className="col-md-4 col-xs-12">
                            <h4>Follow us on social media</h4>
                            <ul className="social-media">
                                <li><a href="#"><i className="ion-social-facebook"></i></a></li>
                                <li><a href="#"><i className="ion-social-twitter"></i></a></li>
                                <li><a href="#"><i className="ion-social-google plus"></i></a></li>
                            </ul>
                            {/*// <!-- end social-media -->*/}
                        </div>
                        {/*// <!-- end col-4 -->*/}
                    </div>
                    {/*// <!-- end row -->*/}
                </div>
                {/*// <!-- end container -->*/}
            </section>
            {/*// <!-- end footer-bar -->*/}
            <footer className="wow fadeIn">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h3>ABOUT US</h3>
                            {/*<span className="border"></span> <img src="/images/logo.png" alt="Image" className="logo"/>*/}
                            <p>Our business has over 40 years of experience in the industry and we strive to satisfy our customers. </p>
                            {/*// <!-- end footer-bar -->*/}
                        </div>
                        {/*// <!-- end col-4 -->*/}
                        <div className="col-md-4">
                            <h3>OUR SERVICES</h3>
                            <span className="border"></span>
                            <div className="clearfix"></div>
                            <ul>
                                <li><a href="/services/roofing">Re-Roofing</a></li>
                                <li><a href="/services/roofing">Roof Inspection</a></li>
                                <li><a href="/services/roofing">Roof Repair</a></li>
                                <li><a href="/services/gutters">Gutters</a></li>
                                <li><a href="/services/skylights">Skylights</a></li>
                                <li><a href="/services/wdo">WDO</a></li>
                            </ul>
                            {/*// <!-- end ul -->*/}
                        </div>
                        {/*// <!-- end col-4 -->*/}
                        <div className="col-xs-12">
                            <div className="sub-footer"><span className="copyright">Copyright © 2021 , Roof Jax Advantage Inc</span>
                                <br/>
                                <span className="themezinho">Created by Jullian Abinsay
                                    {/*with <i className="ion-heart"></i> in Jacksonville*/}
                                </span>
                            </div>
                            {/*// <!-- end sub-footer -->*/}
                        </div>
                        {/*// <!-- end col-12 -->*/}
                    </div>
                    {/*// <!-- row -->*/}
                </div>
                {/*// <!-- end container -->*/}
            </footer>
            {/*  end footer  */}
        </>
    )
}