import {Link} from 'react-router-dom'

export default function Header() {
    return(
        <>
            {/*// <!-- end loading -->*/}
            <div className="transition-overlay"></div>
            {/*// <!-- end transition-overlay -->*/}
            {/*<div className="map-container">*/}
            {/*    <div id="map-placeholder"></div>*/}
            {/*    <span className="close-btn"><i className="ion-close"></i></span>*/}
            {/*</div>*/}
            {/*// <!-- end map -->*/}
            <header>
                <nav className="navbar navbar-default" role="navigation">
                    <div className="top-bar">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h5>Your Neighborhood Roofer</h5>
                                    <span className="phone"><i className="ion-android-call"></i> 1-904-525-7517</span>
                                    {/*<a href="#" className="map"><i className="ion-ios-location"></i> SEE ON MAPS</a>*/}
                                    <a href="/estimate" className="map"> GET AN ESTIMATE</a>
                                    {/*<a href="#" className="language"><img src="images/flag-en.jpg" alt="Image"/> ENGLISH</a>*/}
                                </div>
                                {/*// <!-- end col-12 -->*/}
                            </div>
                            {/*// <!-- end row -->*/}
                        </div>
                        {/*// <!-- end container -->*/}
                    </div>
                    {/*// <!-- end top-bar -->*/}
                    <div className="container">
                        <div className="inner-header gradient">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle toggle-menu menu-left push-body"
                                        data-toggle="collapse" data-target="#nav"><i className="ion ion-navicon"></i>
                                </button>
                                <a className="navbar-brand transition" href="/"><img src="/images/logo.png"
                                                                                              alt="Image"/></a></div>
                            {/*// <!-- end navbar-header -->*/}
                            <div className="collapse navbar-collapse cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left"
                                 id="nav">
                                <ul className="nav navbar-nav">
                                    <li><Link to="/" className="transition">HOME</Link><span></span></li>
                                    <li><Link to="/about" className="transition">ABOUT US</Link><span></span></li>
                                    {/*<li><a href="project.html" className="transition">PROJECTS</a><span></span></li>*/}
                                    <li className="dropdown"><a href='/services/roofing'>SERVICES </a><span></span>
                                        <ul className="dropdown-menu" role="menu">
                                            <li><Link to="/services/roofing" className="transition">
                                                ROOFING</Link></li>
                                            <li><Link to="/services/gutters" className="transition">
                                                GUTTERS</Link></li>
                                            <li><Link to="/services/skylights" className="transition">
                                                SKYLIGHTS</Link></li>
                                            <li><Link to="/services/wdo" className="transition">
                                                WDO</Link></li>
                                        </ul>
                                    </li>
                                    {/*<li><a href="news.html" className="transition">NEWS</a><span></span></li>*/}
                                    <li><Link to="/faqs" className="transition">FAQs</Link><span></span>
                                    </li>
                                    <li><Link to="/estimate" className="transition">GET AN ESTIMATE</Link><span></span>
                                    </li>
                                </ul>
                                <ul className="nav navbar-nav navbar-right">
                                    <li><a href="#"><i className="ion-social-facebook"></i></a></li>
                                    <li><a href="#"><i className="ion-social-twitter"></i></a></li>
                                    <li><a href="#"><i className="ion-social-google plus"></i></a></li>
                                </ul>
                            </div>
                            {/*// <!-- end navbar-collapse -->*/}
                        </div>
                        {/*// <!-- end inner-header -->*/}
                    </div>
                    {/*// <!-- end container -->*/}
                </nav>
                {/*// <!-- end nav -->*/}
            </header>
            {/*// <!-- end header -->*/}
        </>
    )
}