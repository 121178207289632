import {Link} from 'react-router-dom'

export default function ServiceList({active}) {
    let serviceClass = {
        roofing: false,
        gutters: false,
        skylights:false,
        wdo: false
    }

    serviceClass[active] = true

    return(
        <>
            <div className="col-md-4 wow fadeInUp">
                <aside>
                    <ul>
                        <li className={`${serviceClass.roofing ? 'active' : ''}`}>
                            <Link to="/services/roofing" className="transition">ROOFING</Link></li>
                        <li className={`${serviceClass.gutters ? 'active' : ''}`}>
                            <Link to="/services/gutters" className="transition">GUTTERS</Link></li>
                        <li className={`${serviceClass.skylights ? 'active' : ''}`}>
                            <Link to="/services/skylights" className="transition">SKYLIGHTS</Link></li>
                        <li className={`${serviceClass.wdo ? 'active' : ''}`}>
                            <Link to="/services/wdo" className="transition">WDO Services</Link></li>
                    </ul>
                </aside>
            </div>
        </>
    )
}