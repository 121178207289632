import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

// Page Imports
import Home from "./pages/Home";
import ServiceRoofing from "./pages/ServiceRoofing";
import ServiceGutters from "./pages/ServiceGutters";
import ServiceSkylights from "./pages/ServiceSkylights";
import ServiceWDO from "./pages/ServiceWDO";
import Estimate from "./pages/Estimate";
import FAQ from "./pages/FAQ";
import AboutUs from "./pages/AboutUs";

function App() {
  return (
      <Router>
          <Switch>
            <Route exact path="/">
              <Home/>
            </Route>
            <Route exact path="/about">
              <AboutUs/>
            </Route>
            <Route exact path="/faqs">
              <FAQ/>
            </Route>
            <Route exact path="/estimate">
              <Estimate/>
            </Route>
            <Route exact path="/services/roofing">
              <ServiceRoofing />
            </Route>
            <Route exact path="/services/gutters">
              <ServiceGutters/>
            </Route>
            <Route exact path="/services/skylights">
              <ServiceSkylights />
            </Route>
            <Route exact path="/services/wdo">
              <ServiceWDO />
            </Route>
            {/*<Route path="/users">*/}
            {/*  <Users />*/}
            {/*</Route>*/}
            {/*<Route path="/">*/}
            {/*  <Home />*/}
            {/*</Route>*/}
          </Switch>
      </Router>
  );
}

export default App;
