import Header from "../components/Header";
import Footer from "../components/Footer";
import ServiceList from "../components/ServiceList";

export default function FAQ() {
    return(
        <>
            <Header/>
            <section className="page-title">
                <div className="inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-xs-12">
                                {/*<h4>Roofing Services</h4>*/}
                            </div>
                            {/*// <!-- end col-9 -->*/}
                            <div className="col-md-3 hidden-xs">
                                <ol className="breadcrumb">
                                    <li><a href="/">Home</a></li>
                                    <li className="active">Frequently Asked Question</li>
                                </ol>
                            </div>
                            {/*// <!-- end col-3 -->*/}
                        </div>
                        {/*// <!-- end row -->*/}
                    </div>
                    {/*// <!-- end container -->*/}
                </div>
                {/*// <!-- end inner -->*/}
            </section>
            {/*// <!-- end slider -->*/}
            <section className="services">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 wow fadeInRight">
                            <div className="title-box">
                                <h5>Give us a call if you don't find what you're looking for. 904-525-7517</h5>
                                <h2>Frequently Asked Questions</h2>
                                <span></span></div>
                            {/*// <!-- end title-box -->*/}
                            {/*<img src="/images/roof-service.jpg" alt="Image" className="image"/>*/}

                            {/* Question */}
                            <p className="lead"><b>How much does a new shingle roof cost?</b>
                            </p>
                                <p>A new shingle roof will typically cost between $10,000 to $25,000. Cost
                                will also depend on shingle style, how steep the roof is, square footage of the house,
                                    and number of stories.</p><br/>

                            {/* Question */}
                            <p className="lead"><b>How many years is the warranty on a shingle re-roof?</b>
                            </p>
                            <p>We give a 10 year workmanship warranty. Shingle manufacturer warranties range from 25
                                years to a lifetime warranty.</p><br/>

                            {/* Question */}
                            <p className="lead"><b>What are my choices for brand and style of shingles?</b>
                            </p>
                            <p>We use Owens Corning Duration Architectural Shingles, GAF Timberline, and other major
                                brands. The 2 main types of shingles are 3-Tab and Architectural Shingles.</p><br/>

                            {/* Question */}
                            <p className="lead"><b>How do I know if I need a new roof?</b>
                            </p>
                            <p><ul>
                                <li>- If your shingles are discolored</li>
                                <li>- If shingles are curled (not laying flat).</li>
                                <li>- You have missing or torn shingles</li>
                                <li>- Shingles have granule loss (you will see granules in the gutters or on the ground)</li>
                            </ul><br/>
                            If you have any of these conditions get your roof inspected and have the roof repaired or replaced.
                            </p><br/>

                            {/* Question */}
                            <p className="lead"><b>How long does a roof typically last?</b>
                            </p>
                            <p>Depending on the kind of shingles used, a roof will last approximately 12 to 20 years. Wind,
                                weather, and sunlight are also factors of roof life.</p><br/>

                            {/* Question */}
                            <p className="lead"><b>Will home owners insurance pay for the cost of a re-roof or roof repair?</b>
                            </p>
                            <p>Your home owners insurance will usually pay for wind or storm damage on you home but
                                you will have to pay the deductible.</p>

                        </div>
                        {/*// <!-- end col-6 -->*/}
                        {/*// <!-- end col-6 -->*/}
                    </div>
                    {/*// <!-- end row -->*/}
                </div>
                {/*// <!-- end container -->*/}
            </section>
            <Footer/>
        </>
    )
}