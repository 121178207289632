import {Link} from 'react-router-dom'
import Header from "../components/Header"
import Footer from "../components/Footer"
import EmailKey from "../components/EmailKey";
import emailjs from 'emailjs-com';

export default function Estimate() {

    const handleSubmit = (e) => {
        console.log('Pressed')
        console.log(e)
        e.preventDefault() // Prevents default refresh by the browser
        emailjs.sendForm(`service_dfanzzo`, EmailKey.TEMPLATE_ID, e.target, EmailKey.USER_ID)
            .then((result) => {
                    alert("Message Sent, We will get back to you shortly", result.text)
                },
                (error) => {
                    alert("An error occurred, Please try again", error.text)
                })
    }

    return(
        <>
            <Header/>
            <section className="page-title">
                <div className="inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-xs-12">
                                <h4>GET AN ESTIMATE</h4>
                            </div>
                            {/*// <!-- end col-9 -->*/}
                            <div className="col-md-3 hidden-xs">
                                <ol className="breadcrumb">
                                    <li><a href="/">Home</a></li>
                                    <li className="active">Get an Estimate</li>
                                </ol>
                            </div>
                            {/*// <!-- end col-3 -->*/}
                        </div>
                        {/*// <!-- end row -->*/}
                    </div>
                    {/*// <!-- end container -->*/}
                </div>
                {/*// <!-- end inner -->*/}
            </section>
            {/*// <!-- end slider -->*/}
            {/*// <!-- end contact -->*/}
            <section className="quote background-1 wow fadeInUp" data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <h5>TO GET AN ESTIMATE, FILL OUT THE FORM BELOW OR EVEN GIVE US A CALL</h5>

                            <h2>1-904-525-7517</h2>
                            <span className="border"></span>
                        </div>
                        {/*// <!-- end col-12 -->*/}
                    </div>
                    {/*// <!-- end row -->*/}
                </div>
                {/*// <!-- end container -->*/}
            </section>
            {/*// <!-- end video-bg -->*/}
            <section className="contact-form wow fadeInUp">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <form id="contact" name="contact" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" name="name" id="name" required/>
                                </div>
                                {/*// <!-- end form-group -->*/}
                                {/*<div className="form-group">*/}
                                {/*    <label>E-mail</label>*/}
                                {/*    <input type="text" name="email" id="email" required/>*/}
                                {/*</div>*/}
                                {/*// <!-- end form-group -->*/}
                                {/*<div className="form-group">*/}
                                {/*    <label>City</label>*/}
                                {/*    <input type="text" name="city" id="city" required/>*/}
                                {/*</div>*/}
                                {/*// <!-- end form-group -->*/}
                                <div className="form-group">
                                    <label>Phone</label>
                                    <input type="text" name="phone" id="phone" required/>
                                </div>
                                {/*// <!-- end form-group -->*/}
                                <div className="form-group full-width">
                                    <label>Address</label>
                                    <textarea name="address" id="address" required></textarea>
                                </div>
                                {/*// <!-- end form-group -->*/}
                                <div className="form-group full-width">
                                    <label>Requested Service/s</label>
                                    <input type="text" name="service" id="service" placeholder="Re-roof, Roof Repair, Gutters, Skylights, etc." required/>
                                </div>
                                {/*// <!-- end form-group -->*/}
                                <div className="form-group">
                                    <button id="submit" type="submit" name="submit">GET ESTIMATE</button>
                                </div>
                                {/*// <!-- end form-group -->*/}
                            </form>
                            <div id="success" className="alert alert-success" role="alert">
                                <p>Your message was sent successfully! We will be in touch as soon as we can.</p>
                            </div>
                            <div id="error" className="alert alert-danger" role="alert">
                                <p>Something went wrong, try refreshing and submitting the form again.</p>
                            </div>
                        </div>
                        {/*// <!-- end col-12 -->*/}
                    </div>
                    {/*// <!-- end row -->*/}
                </div>
                {/*// <!-- end container -->*/}
            </section>
            <Footer/>
        </>
    )
}