import Header from "../components/Header";
import Footer from "../components/Footer";
import ServiceList from "../components/ServiceList";

export default function ServiceSkylights() {
    return(
        <>
            <Header/>
            <section className="page-title">
                <div className="inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-xs-12">
                                {/*<h4>Roofing Services</h4>*/}
                            </div>
                            {/*// <!-- end col-9 -->*/}
                            <div className="col-md-3 hidden-xs">
                                <ol className="breadcrumb">
                                    <li><a href="/">Home</a></li>
                                    <li className="active">Skylight Services</li>
                                </ol>
                            </div>
                            {/*// <!-- end col-3 -->*/}
                        </div>
                        {/*// <!-- end row -->*/}
                    </div>
                    {/*// <!-- end container -->*/}
                </div>
                {/*// <!-- end inner -->*/}
            </section>
            {/*// <!-- end slider -->*/}
            <section className="services">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 wow fadeInRight">
                            <div className="title-box">
                                <h5>Providing over 40 Years of Experience in the Industry</h5>
                                <h2>Skylight Services</h2>
                                <span></span></div>
                            {/*// <!-- end title-box -->*/}
                            <p className="lead"><b>All of our policies and commitments will be followed on continuous
                                basis with participation and support of our employees for continuous improvement.</b>
                            </p>
                            <img src="/images/skylight.jpg" alt="Image" className="image"/>
                                <p>Builder Construction, has always given priority to green areas and water sources in
                                    all its projects it has developed across the country with different themes. The
                                    company continues with the construction of new projects in Newyork, Sao Paulo, Rio
                                    de Janeiro and Barcelona and adds value to its surrounding area.</p>
                                <p>The massive Bonilha project, which is considered as the second biggest investment of
                                    South-eastern West Cost region after South-eastern Bonilha Project, located in
                                    Newyork province, has many original features and accommodates 3.470 residences, has
                                    the biggest natural pond of the world which would bring the sea life to Newyork.</p>
                                <p>Our customers, employees and subcontractors are the reasons for our existence. We
                                    will take all necessary precautions in order to render our work environments more
                                    secure and healthy and we will prioritize maintenance of such precautions.</p>
                                <p>We will not make concessions to our high quality product and service policy,
                                    principles and honest identity regardless of the conditions. Our work health and
                                    safety policy will be available to all our stakeholders and customer satisfaction
                                    will be the fundamental element.</p>
                        </div>
                        {/*// <!-- end col-6 -->*/}
                        <ServiceList active="skylights"/>
                        {/*// <!-- end col-6 -->*/}
                    </div>
                    {/*// <!-- end row -->*/}
                </div>
                {/*// <!-- end container -->*/}
            </section>
            <Footer/>
        </>
    )
}